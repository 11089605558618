.product-list {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
}

.product-list .item {
  width: calc(100% - 12px);
  position: relative;
  border-radius: 3px;
  /* background-color: white; */
  background-color: #ffffff;
  border: 1px solid #dedede;
  /* box-shadow: 0 2px 2px rgba(0,90,250,0.05),
    0 4px 4px rgba(0,90,250,0.05),
    0 8px 8px rgba(0,90,250,0.05),
    0 16px 16px rgba(0,90,250,0.05); */
  padding:15px;
  margin:6px;
  box-sizing: border-box;
  /* transition: box-shadow ease-in 0.2s; */
}

/* .product-list .item:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05),
    0 4px 4px rgba(0, 0, 0, 0.05),
    0 8px 8px rgba(0, 0, 0, 0.05),
    0 16px 16px rgba(0, 0, 0, 0.05);
} */



section.product-list > div.item:hover:after {
	content: "";
	display: block;
	width: 100%;
	height: 4px;
	position: absolute;
	bottom: -4px;
	left: 0;
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

section.product-list > div.item:hover:after {
	background-color: var(--primary);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

section.product-list > div.item:hover {
	background: #ffffff;
	position: relative;
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	/* height: 100%; */
}

section.product-list > div.item:hover {
	background: #ffffff;
  box-shadow: 0px 10px 20px #00000029;
}


/* Force new columns */
.product-list::before,
.product-list::after {
  content: "";
  flex-basis: 100%;
  width: 0;
  order: 2;
}

@media screen and (min-width:480px) {
  .product-list .item {
    width: calc(100% - 12px);
  }
}

@media screen and (min-width:540px) {
  .product-list .item {
    width: calc(50% - 12px);
  }
}

@media screen and (min-width:768px) {
  .product-list .item {
    width: calc(33% - 12px);
  }
}

@media screen and (min-width:1280px) {
  .product-list .item {
    width: calc(25% - 12px);
  }
}

@media screen and (min-width:1600px) {
  .product-list .item {
    width: calc(20% - 12px);
  }
}

@media screen and (min-width:1960px) {
  .product-list .item {
    width: calc(16.666% - 12px);
  }
}

@media screen and (min-width:2300px) {
  .product-list .item {
    width: calc(14.28% - 12px);
  }
}

@media screen and (min-width:2640px) {
  .product-list .item {
    width: calc(12.5% - 12px);
  }
}

@media screen and (min-width:3000px) {
  .product-list .item {
    width: calc(11.11% - 12px);
  }
}

@media screen and (min-width:3300px) {
  .product-list .item {
    width: calc(10% - 12px);
  }
}
