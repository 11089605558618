div.mySlider div.overlay {
  content:"";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom right,#1F2937 25%,rgba(0,0,0,0) 45%);
  opacity:0.6;
  z-index:1;
}