/* @import url(https://fonts.googleapis.com/css?family=opensans:500); */

div.not-found {
  background: var(--primary);
  color:#fff;
  font-family: 'Open Sans', sans-serif;
  /* max-height:100%; */
  overflow: hidden;
  width:100%;
  height:100%;
  box-sizing: border-box;
}

div.not-found .c {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width:100%;
  margin:-15px 0 0;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 2;
}

div.not-found ._404 {
  font-size: 220px;
  position: relative;
  display: inline-block;
  z-index: 2;
  height: 190px;
  letter-spacing: 15px;
  line-height:180px;
}

div.not-found ._1 {
  text-align:center;
  display:block;
  position:relative;
  letter-spacing: 12px;
  font-size: 4em;
  line-height: 80%;
}

div.not-found ._2 {
  text-align:center;
  display:block;
  position: relative;
  font-size: 20px;
}

div.not-found .text {
  font-size: 70px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 19px 0px 0px 0px;
  /* top: 256.301px; */
  z-index: 3;
  width: 100%;
  line-height: 1.2em;
  display: inline-block;
}

div.not-found .btn {
  border:1px solid transparent;
  background-color: white;
  position: relative;
  display: inline-block;
  width: 358px;
  padding: 5px;
  z-index: 5;
  font-size: 25px;
  margin:12px auto;
  color:var(--primary);
  text-decoration: none;
  margin-right: 10px;
}

div.not-found .btn:hover {
  background-color: transparent;
  color:white;
  border:1px solid white;
}

div.not-found .right {
  float:right;
  width:60%;
}

div.not-found hr {
  padding: 0;
  border: none;
  border-top: 5px solid #fff;
  color: #fff;
  text-align: center;
  margin: 16px auto;
  width: 420px;
  height:10px;
  z-index: -10;
}

div.not-found #clouds {
  position:relative;
  height:100%;
  z-index:1;
}

div.not-found .cloud {
  width: 350px; height: 120px;

  background: #FFF;
  background: linear-gradient(to top, #FFF 100%);
  border-radius: 100px;

  position: absolute;
  margin: 120px auto 20px;
  z-index:-1;
  transition: ease 1s;
}

div.not-found .cloud:after, div.not-found .cloud:before {
  content: '';
  position: absolute;
  background: #FFF;
  z-index: -1;
}

div.not-found .cloud:after {
  width: 100px; 
  height: 100px;
  top: -50px; 
  left: 50px;
  border-radius: 100px;
}

div.not-found .cloud:before {
  width: 180px; 
  height: 180px;
  top: -90px; 
  right: 50px;

  border-radius: 200px;
}

div.not-found .x1 {
  top:-50px;
  left:100px;
  transform: scale(0.3);
  opacity: 0.9;
  animation: moveclouds 15s linear infinite;
}

div.not-found .x1_5{
  top:-80px;
  left:250px;
  transform: scale(0.3);
  animation: moveclouds 17s linear infinite;
}

div.not-found .x2 {
  left: 250px;
  top:30px;
  transform: scale(0.6);
  opacity: 0.6; 
  animation: moveclouds 25s linear infinite;
}

div.not-found .x3 {
  left: 250px; 
  bottom: -70px;
  transform: scale(0.6);
  opacity: 0.8; 
  animation: moveclouds 25s linear infinite;
}

div.not-found .x4 {
  left: 470px; 
  bottom: 20px;
  transform: scale(0.75);
  opacity: 0.75;
  animation: moveclouds 18s linear infinite;
}

div.not-found .x5 {
  left: 200px; 
  top: 300px;
  transform: scale(0.5);
  opacity: 0.8; 
  animation: moveclouds 20s linear infinite;
}

@keyframes moveclouds {
  0% {
    margin-left: 1300px;
  }
  100% {
    margin-left: -700px;
  }
}
