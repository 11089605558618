footer {
  width: 100%;
  height: 100px;
  min-height:100px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer img {
  max-width:100px;
  margin-left: 0.5rem;
}

footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:.9em;
}