.article {
	padding: 56px 20px;
	min-height: 100%;
	width: 100%;
}


@media screen and (max-width:767px) {
	div.tabs ul.tab_header li:not(.hidden):nth-child(odd) {
		background-color: #f5f5f5;
	}
}

@keyframes slide-in {
	0% { transform: translateX(20%); }
	100% { transform: translateX(0%); }
}

@keyframes slide-out {
	0% { transform: translateX(0%); }
	100% { transform: translateX(20%); }
}

nav.detail-nav button {
	position:relative;
}

nav.detail-nav button svg {
	position:relative;
	z-index:3;
}

nav.detail-nav button span {
	position:absolute;
	z-index:2;
	transform: translateX(20%);
	animation: slide-out 0.2s backwards;
}

nav.detail-nav button:hover span {
	display:block;
	color: var(--primary);
	right:31px;
	background-color:white;
	padding:10px 12px 10px 8px;
	border-radius: 3px;
	border:1px solid rgba(249, 250, 251, var(--tw-bg-opacity));
	border-right:none;
	border-left:3px solid var(--primary);
	transform: translateX(0%);
	animation: slide-in 0.2s backwards;
}

nav.detail-nav button:not(.bg-white):hover span {
	background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
