@keyframes rainbow {
  from {background-position: -100vh 0}
  to {background-position: 100vh 0}
} 
@-moz-keyframes rainbow {
  from {background-position: -100vh 0}
  to {background-position: 100vh 0}
}
@-webkit-keyframes rainbow {
  from {background-position: -100vh 0}
  to {background-position: 100vh 0}
}
@-ms-keyframes rainbow {
  from {background-position: -100vh 0}
  to {background-position: 100vh 0}
}
@-o-keyframes rainbow {
  from {background-position: -100vh 0}
  to {background-position: 100vh 0}
}

.rainbow-box {
  border: solid 1px transparent;
  border-radius: 6px;
  background: linear-gradient(white, white), 
    repeating-linear-gradient(to right,#b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation-name: rainbow;
  animation-duration:  8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.rainbow-font {
  /* background: linear-gradient(var(--primary), var(--primary)),  */
    /* repeating-linear-gradient(to right,#b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%); */
  color: transparent;
  background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);   
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-name: rainbow;
  animation-duration:  20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.img-load {
  border: solid 1px transparent;
  border-radius: 2px;
  background: linear-gradient(white, white), 
    repeating-linear-gradient(to right,#b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation-name: rainbow;
  animation-duration:  30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.img-error {
  border: solid 1px transparent;
  border-radius: 2px;
  border-color: rgb(248, 113, 113, .3);
}
