
.nav-tree ul li {
  position:relative;
  padding-left:1.0rem;
}

.nav-tree ul li ul {
  display:none;
}

.nav-tree ul li a {
  display:inline-block;
  line-height:1.2rem;
  vertical-align: top;
  padding:0.2rem 2rem 0.2rem 0.6rem;
  width: 100%;
  /* word-break: break-word; */
  max-width: 16rem;
  overflow-wrap: break-word;
}

.nav-tree ul li.active > a {
  position:relative;
  background-color:white;
  color: var(--primary);
}

.nav-tree ul li.active > a:before {
  content:"";
  position:absolute;
  top:0;
  left:-1.875rem;
  /* right:0; */
  height:100%;
  border-left: 0.3rem solid var(--primary);
}

.nav-tree ul li.active > button svg {
  color: var(--primary);
}

.nav-tree ul li input[type='checkbox'] {
  display:inline-block;
}

.nav-tree ul li button {
  position:absolute;
  top:0;
  left:0;
}

.nav-tree li > button.show + a + ul {
  display:block;
}

.nav-tree li > button.show > svg {
  transform:rotate(90deg);
}

